import React, {Component} from 'react';
import {Grid, Cell, Card, CardTitle, CardText, IconButton, CardMenu, CardActions, Button} from 'react-mdl'

class Projects   extends Component{
    constructor(props){
        super(props);
        this.state = {activeTab: 0}
    }
    
    render(){
        return(
            // <div className="projects" >
            //     <div className="projects-content" id="projects">
            // TODO: make this resizable
                <Grid>
                    <Cell col={12}>
                        <div className="content">
                            <div className="projects-grid" >
                                {/* Project 2 */}
                                <Card shadow={5} style={{minWidth: '450px', margin: 'auto'}}>
                                    <CardTitle style={{color: '#fff', height: '176px', background: 'url(http://www.getmdl.io/assets/demos/welcome_card.jpg) center / cover'}}>Welcome</CardTitle>
                                    <CardText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Mauris sagittis pellentesque lacus eleifend lacinia...
                                    </CardText>
                                    <CardActions border>
                                        <Button colored>Get Started</Button>
                                    </CardActions>
                                </Card>
                                {/* Project 2 */}
                                <Card shadow={5} style={{minWidth: '450px', margin: 'auto'}}>
                                    <CardTitle style={{color: '#fff', height: '176px', background: 'url(http://www.getmdl.io/assets/demos/welcome_card.jpg) center / cover'}}>Welcome</CardTitle>
                                    <CardText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Mauris sagittis pellentesque lacus eleifend lacinia...
                                    </CardText>
                                    <CardActions border>
                                        <Button colored>Get Started</Button>
                                    </CardActions>
                                </Card>
                                {/* Project 3 */}
                                <Card shadow={5} style={{minWidth: '450px', margin: 'auto'}}>
                                    <CardTitle style={{color: '#fff', height: '176px', background: 'url(http://www.getmdl.io/assets/demos/welcome_card.jpg) center / cover'}}>Welcome</CardTitle>
                                    <CardText>
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        Mauris sagittis pellentesque lacus eleifend lacinia...
                                    </CardText>
                                    <CardActions border>
                                        <Button colored>Get Started</Button>
                                    </CardActions>
                                </Card>
                                
                            </div>
                        </div>
                    </Cell>
                </Grid>
            //     </div>
            // </div>
        )
    }
}

export default Projects;
