import React, {Component} from 'react';
import {Grid, Cell} from 'react-mdl';
class LandingPage extends Component{
    render(){
        return(
            <div className="landingpage">
                <div className= "landingpage-content" style={{width: '100%', margin: 'auto'}} id="landing page">
                    <Grid className="landing-grid">
                        <Cell col={12}>
                            {/* Avatar Image */}
                            <img 
                                src="https://lh3.googleusercontent.com/gVQXyMlFnrKSaM_limFzxaiGHGkfEroXpYfmmw3HDKEjChqRTV_Y_-_f5HWtEMDt6F3xDRe_cf1szDiLXSBTOSrxwg" 
                                alt="avatar"
                                className="avatar-img"
                                />
                            {/* Banner */}
                            <div className="banner-text">
                                <h1>Full Stack Web Devloper</h1>
                                <hr/>
                                <p>HTML/CSS | Bootstrap | Javascript | React | Python | Learning</p>
                            {/* Social Links */}
                                <div className="social-links">
                                    <a href="https://google.com" rel="noopener noreferrer" target="_blank" >
                                        <i className="fa fa-linkedin-square" aria-hidden="true"/>
                                    </a>
                                    <a href="https://google.com" rel="noopener noreferrer" target="_blank" >
                                        <i className="fa fa-github-square" aria-hidden="true"/>
                                    </a>

                                </div>
                            </div>
                        </Cell>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default LandingPage;
