import React, {Component} from 'react';
// import { Document, Page, pdfjs  } from 'react-pdf';
class Resume extends Component{
    // state = {
    //     numPages: null,
    //     pageNumber: 1,
    // }

    // onDocumentLoadSuccess = ({numPages}) => {
    //     this.setState({numPages});
    // }
    render(){
        // const { pageNumber, numPages } = this.state;
        return(
            <div className="resume">
                <div className="resume-content" id="resume">
                    <h1>resume</h1>
                    {/* <Document 
                        file="https://storage.turbo360.co/portfolio-website-xeghma/Benjamin_Miller_s__Resume%20(9).pdf"
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber}/>
                    </Document> */}
                </div>
            </div>
        )
    }
}

export default Resume;
