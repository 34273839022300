import React, {Component} from 'react';

class AboutMe extends Component{
    render(){
        return(
            <div className="aboutme">
                <div className="aboutme-content" id="aboutme">
                    <h1>about me</h1>
                    <p>I'm a Computer Science major graduating from the Univeristy of Pittsburgh in August 2019. I think of myself as a constant learner as I spend every waking moment listening to tech podcasts, expanding my programming knowledge, and reading books. In my free time I enjoy tennis, cooking, and biking through the beautiful city of Pittsburgh.</p>
                </div>
            </div>
        )
    }
}

export default AboutMe;
