import React, {Component} from 'react';

class Contact extends Component{
    render(){
        return(
            <div className="contact">
                <div className="contact-content" id="contact">
                    <h1>Contact</h1>
                </div>
            </div>
        )
    }
}

export default Contact;
